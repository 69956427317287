import React from "react";
import { string, bool, func } from "prop-types";
import { Modal } from "antd";
import YoutubeVideo from "../YoutubeVideo";
import Heading from "../Base/HeadingBuilder";
import closeIcon from "../../images/svg/close-icon.svg";
import bg from "../../images/svg/vector-15.svg";

const opts = {
  height: "350",
  width: "570",
  playerVars: {
    autoplay: 1,
  },
};

const YoutubeVideoModal = ({
  isVisible,
  onClose,
  videoId,
  title,
  posterName,
  customVideoOptions,
}) => {
  return (
    <Modal
      className="video-modal relative"
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      closeIcon={() => null}
    >
      <div className="mx-2 sm:mx-12 mt-12 flex flex-row items-baseline justify-between">
        <Heading injectionType={4} className="text-black ">
          {title}
        </Heading>

        <button
          type="button"
          className="border-none outline-none p-4 bg-white cursor-pointer"
          onClick={onClose}
        >
          <img
            className="w-[24px] h-[24px] xl:w-auto xl:h-auto"
            src={closeIcon}
            alt="close"
          />
        </button>
      </div>

      <div className="mx-2 sm:mx-12 my-5 2xl:my-14 relative z-1">
        <YoutubeVideo
          videoUrl={videoId}
          videoPoster={posterName}
          customOptions={opts}
        />
      </div>

      <img src={bg} alt="background" className="absolute right-0 bottom-0" />
    </Modal>
  );
};

YoutubeVideoModal.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  videoId: string.isRequired,
  title: string,
  posterName: string,
};

YoutubeVideoModal.defaultProps = {
  title: "",
  posterName: "",
};

export default YoutubeVideoModal;
