import React, { useState, useRef, useEffect } from "react";
import { string, func, arrayOf, bool } from "prop-types";
import useOnClickOutside from "../../../hooks/use-on-click-outside";
import chevron from "../../../images/svg/chevron-gray.svg";
import "./styles.css";

const Select = ({
  className,
  placeholder,
  options,
  onChange,
  active,
  disabled,
}) => {
  const ref = useRef();
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (disabled) {
      setSelected("");
    }
  }, [disabled]);

  useOnClickOutside(ref, () => setExpanded(false));

  const toggle = () => (disabled ? null : setExpanded(!expanded));

  const handleChange = (ev) => {
    const { value } = ev.target.dataset;
    onChange(value);
    setSelected(value);
    setExpanded(false);
  };

  return (
    <div
      ref={ref}
      className={`${className}
        so-select
        relative h-full
        my-2
    `}
    >
      <button
        type="button"
        className={`
          w-full h-full
          border-solid border-black-800 rounded-lg outline-none
          py-2.5 px-6
          ${disabled ? "text-black-300" : "cursor-pointer text-black"}
          ${active || disabled ? "bg-black-800" : "bg-white"}
          transition transition-colors
          flex flex-row justify-between items-center
      `}
        onClick={toggle}
      >
        <span>{selected || placeholder}</span>
        <img
          src={chevron}
          alt={chevron}
          className={`transition transition-all ${
            expanded ? "rotate-180" : ""
          }`}
        />
      </button>

      <ul
        className={`
          list-none py-2 pb-1 px-0 absolute left-0 right-0 top-14 bottom-0
          bg-white w-full h-full z-[9] overflow-hidden
          rounded-lg border-solid border-black-800
          ${expanded ? "visible" : ""}
        `}
      >
        {selected && (
          <li className="text-black-500 py-2 px-4">{placeholder}</li>
        )}
        {options.map((option) => (
          <li key={option} className="rounded-md">
            <button
              type="button"
              className={`
                  outline-none border-none
                  w-full h-full text-left 
                  bg-white hover:bg-black-800
                  cursor-pointer py-2 px-4
                  rounded-md
                  ${selected === option ? "text-primary" : ""}
                `}
              data-value={option}
              onClick={handleChange}
            >
              {option}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

Select.propTypes = {
  className: string,
  placeholder: string,
  options: arrayOf(string).isRequired,
  onChange: func.isRequired,
  active: bool,
  disabled: bool,
};

Select.defaultProps = {
  className: "",
  placeholder: "",
  active: false,
  disabled: false,
};

export default Select;
