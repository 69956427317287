import React from "react";
import { string, bool, func } from "prop-types";

export const pillProps = {
  value: string.isRequired,
  label: string.isRequired,
  active: bool,
  onClick: func.isRequired,
};

const Pill = (props) => {
  const { className, value, label, active, onClick } = props;

  const clickHandler = () => onClick(value);

  return (
    <button
      type="button"
      className={`${className} 
      border-solid border-black-800 text-black rounded-lg outline-none
      py-2.5 px-6 my-2 cursor-pointer
      ${active ? "bg-black-800" : "bg-white"}
      betterhover:hover:bg-black-800
      transition transition-colors
  `}
      onClick={clickHandler}
    >
      {label}
    </button>
  );
};

Pill.propTypes = {
  className: string,
  ...pillProps,
};

Pill.defaultProps = {
  className: "",
  active: false,
};

export default Pill;
