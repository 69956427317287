import React, { useEffect, useState } from "react";
import { string, any, bool } from "prop-types";
import YouTube from "react-youtube";
import { motion, useAnimation } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import { useInView } from "react-intersection-observer";
import BackgroundSection from "../../components/_NewAnimated/NewVideo/VideoPosterImage";
import play from "../../images/svg/play-blue.svg";
import useStartMotion from "../../hooks/use-start-motion";

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const opts = {
  height: "690",
  width: "100%",
  playerVars: {
    autoplay: 1,
  },
};

const YoutubeVideo = (props) => {
  const { className, videoUrl, videoPoster, customOptions, autoplay } = props;
  const [playing, setPlaying] = useState(autoplay);
  const [videoComponent, setVideoComponent] = useState(null);
  const titleControls = useAnimation();
  const { ref, inView, controls } = useStartMotion();

  const endPlaying = () => {
    setPlaying(false);
  };
  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  };
  const handleVideo = () => {
    setPlaying(true);
  };

  useEffect(() => {
    if (!isMobileOnly) {
      if (inView) {
        controls.start("show");
        titleControls.start("show");
      }
      if (!inView) {
        controls.start("exit");
        titleControls.start("exit");
      }
    }
  }, [controls, inView, titleControls]);

  useEffect(() => {
    const vid = (
      <YouTube
        videoId={videoUrl}
        opts={customOptions || opts}
        onReady={onReady}
        onEnd={endPlaying}
        className={`video-cont rounded-large block `}
      />
    );
    setVideoComponent(vid);
  }, []);

  return (
    <motion.div
      className={`${className} about-full relative m-0 w-full overflow-hidden z-0`}
      ref={ref}
      initial={isMobileOnly ? "visible" : "hidden"}
      variants={fadeVariants}
      animate={controls}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      {playing ? (
        <>{videoComponent}</>
      ) : (
        <div ref={ref}>
          <div
            className={`overflow-hidden ${!playing ? "block" : "hidden"}`}
            style={{ borderRadius: 32 }}
          >
            <BackgroundSection
              imageName={videoPoster}
              className="rounded-large"
              style={{ width: "105%" }}
            >
              <div className="video-wrapper lg:h-[690px] h-[400px] relative">
                <button
                  type="button"
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[134px] h-[134px] rounded-full border-none bg-primary-30"
                  onClick={handleVideo}
                >
                  <img src={play} alt="" />
                </button>
              </div>
            </BackgroundSection>
          </div>
        </div>
      )}
    </motion.div>
  );
};

YoutubeVideo.propTypes = {
  className: string,
  videoUrl: string.isRequired,
  videoPoster: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customOptions: any,
  autoplay: bool,
};
YoutubeVideo.defaultProps = {
  className: "",
  customOptions: null,
  autoplay: false,
};

export default YoutubeVideo;
