import React from "react";
import { navigate } from "gatsby";
import { string, func, bool, oneOfType, object, objectOf } from "prop-types";
import chevron from "../../../images/svg/chevron_secondary.svg";
import chevronWhite from "../../../images/svg/chevron_left.svg";
import chevronLight from "../../../images/svg/chevron-light.svg";
import spinner from "../../../images/svg/loading.svg";

const CtaSecondary = ({
  className,
  title,
  onClick,
  target,
  large,
  withWhiteChevron,
  reverseIcon,
  openInNewTab,
  style,
  lightVariant,
  noIcon,
  loading,
}) => {
  const commonStyles = `
    ${className} 
    cta-s
    ${large ? "text-cta-large" : "text-cta"}
    ${lightVariant ? "text-white" : large ? "text-black" : "text-primary"}
    font-bold  cursor-pointer
    px-0 py-0
    flex flex-row items-center
    bg-white bg-opacity-0 border-none
    bg-growing-underline
  `;

  const urlOpener = () => {
    if (openInNewTab) {
      return window.open(target, "_blank").focus();
    }

    return navigate(target);
  };

  const clickHandler = target ? urlOpener : onClick;

  if (loading) {
    return (
      <div className={`${className} flex justify-center`}>
        <img className="w-16 h-16" alt="loading" src={spinner} />
      </div>
    );
  }

  if (!large) {
    return (
      <button
        className={`${commonStyles} hover:underline py-2.5`}
        type="button"
        onClick={clickHandler}
        data-href={target}
      >
        {title}
      </button>
    );
  }

  return (
    <>
      <button
        className={`
          ${commonStyles} 
          relative
          flex flex-row
          hover:text-primary-70 transition-all
       `}
        type="button"
        onClick={clickHandler}
        style={style}
        data-href={target}
      >
        {reverseIcon && !noIcon && (
          <img
            className="mr-2 transform rotate-180"
            src={withWhiteChevron ? chevronWhite : chevron}
            alt="chevron icon"
          />
        )}
        <div
          className={`cta-s__title relative pt-1 ${
            reverseIcon ? "reverse" : ""
          }`}
        >
          <span>{title}</span>
          <span className="cta-s__underline absolute w-full" />
        </div>
        {!reverseIcon && !noIcon && (
          <img
            className="ml-2"
            src={
              lightVariant
                ? chevronLight
                : withWhiteChevron
                ? chevronWhite
                : chevron
            }
            alt="chevron icon"
          />
        )}
      </button>

      <style jsx>{`
        .cta-s__underline {
          height: 1px;
          background: ${lightVariant ? "white" : "rgba(23, 105, 255, 0.1)"};
          bottom: -2px;
          left: 0;
          transition: transform 0.3s ease;
        }
        .cta-s__underline:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background: #74a5ff;
          transform: scaleX(0);
          transition: transform 0.3s ease;
        }
        .cta-s:hover .cta-s__underline:before {
          background: #74a5ff;
          transform: scaleX(1);
        }
      `}</style>
    </>
  );
};

CtaSecondary.propTypes = {
  className: string,
  title: string.isRequired,
  onClick: func,
  target: oneOfType([string, null]),
  large: bool,
  withWhiteChevron: bool,
  reverseIcon: bool,
  openInNewTab: bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: object,
  lightVariant: bool,
  noIcon: bool,
  loading: bool,
};

CtaSecondary.defaultProps = {
  className: "",
  onClick: () => true,
  target: null,
  large: false,
  withWhiteChevron: false,
  reverseIcon: false,
  openInNewTab: false,
  style: {},
  lightVariant: false,
  noIcon: false,
  loading: false,
};

export default CtaSecondary;
