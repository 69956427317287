import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { isMobileOnly } from "react-device-detect";
import { useAnimation } from "framer-motion";

const useStartMotion = (animations = true) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
  });
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    if (!animationPlayed && animations && inView && !isMobileOnly) {
      controls.start("visible");
      setAnimationPlayed(true);
    }
  }, [inView]);

  return { ref, inView, controls };
};

export default useStartMotion;
