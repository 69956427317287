import React, { useState, useEffect } from "react";
import { string, arrayOf, shape, object } from "prop-types";
import { Modal } from "antd";
import Title from "../Base/Title";
import Pill from "../Base/Pill";
import Select from "../Base/Select";
import ResourceCard from "./resource-card";
import { ghostApi } from "../../api/ghostApi";
import {
  sortByDateDescending,
  filterDataToRender,
  normalizeBlogData,
  normalizeInfographicData,
} from "./helpers";
import CtaSecondary from "../Base/CtaSecondary";
import {
  resourceGoalsToGhostSlugsMap,
  resourceIndustries,
  resourceGoals,
  resourceTags,
  resourceCategories,
} from "../../constants";
import YoutubeVideoModal from "../YoutubeVideoModal";

const industrySelectOptions = [
  "All",
  resourceIndustries.RESTAURANTS,
  resourceIndustries.ENTERPRISE,
  resourceIndustries.RETAIL,
];

const goalSelectOptions = [
  "All",
  resourceGoals.STREAMLINE_OPERATIONS,
  resourceGoals.GET_SMARTER_DATA,
  resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
  resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
  resourceGoals.REACH_NEW_CUSTOMERS,
  resourceGoals.CONNECT_AND_SELL_ONLINE,
  resourceGoals.BUILD_CUSTOMER_LOYALTY,
];

const ResourcesCards = ({ defaultResource, className, title, pills, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resource, setResource] = useState(defaultResource);
  const [industryFilter, setIndustryFilter] = useState("");
  const [goalFilter, setGoalFilter] = useState("");
  const [loading, setLoading] = useState(false);

  const [dataToRender, setDataToRender] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [infographics, setInfographics] = useState([]);
  const [blogPostsPage, setBlogPostsPage] = useState(1);
  const [hasMoreBlogArticles, setHasMoreBlogArticles] = useState(null);
  const [videoData, setVideoData] = useState({
    title: "",
    videoId: "",
    posterName: "",
  });

  useEffect(() => {
    if (
      resource === resourceCategories.BLOG ||
      resource === resourceCategories.VIDEOS
    ) {
      setGoalFilter("");
    }
  }, [resource]);

  const loadInfographics = () => {
    ghostApi.posts
      .browse({
        include: "tags",
        filter: "tags:[infographic]",
      })
      .then((data) => {
        const newInfographics = data.map(normalizeInfographicData);
        setInfographics(newInfographics);
      });
  };

  const loadBlogPosts = (filter = "", goal = "", page = 1) => {
    setLoading(true);
    ghostApi.posts
      .browse({
        include: "tags",
        filter: `${filter && filter !== "All" ? `tags:[${filter}]` : ""}`,
        limit: 9,
        order: "published_at DESC",
        page,
      })
      .then((rawPosts) => {
        setLoading(false);
        const posts = rawPosts.map(normalizeBlogData);
        setBlogPosts(page === 1 ? posts : [...blogPosts, ...posts]);
        setBlogPostsPage(page + 1);
        setHasMoreBlogArticles(rawPosts?.meta?.pagination?.next || null);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadBlogPosts();
    loadInfographics();
  }, []);

  useEffect(() => {
    return industryFilter || industryFilter !== "All"
      ? loadBlogPosts(
          resourceGoalsToGhostSlugsMap[industryFilter],
          goalFilter,
          1
        )
      : loadBlogPosts("", goalFilter, blogPostsPage);
  }, [industryFilter, goalFilter]);

  useEffect(() => {
    setDataToRender(
      sortByDateDescending(
        filterDataToRender(
          resource,
          data,
          blogPosts,
          infographics,
          industryFilter,
          goalFilter
        )
      )
    );
  }, [resource, blogPosts, infographics, industryFilter, goalFilter]);

  return (
    <section className={`${className} px-4`}>
      {title && (
        <Title className="text-center" withBlueSymbol>
          {title}
        </Title>
      )}

      <div className="lg:text-center flex flex-row flex-wrap gap-x-2 justify-center">
        {pills.map((item) => (
          <Pill
            key={item.value}
            {...item}
            active={item.value === resource}
            onClick={setResource}
          />
        ))}
      </div>

      <div className="flex flex-col sm:flex-row justify-center gap-x-2">
        <Select
          className="w-full sm:max-w-[400px]"
          placeholder="Filter by industry"
          options={industrySelectOptions}
          onChange={(value) => setIndustryFilter(value)}
          active={Boolean(industryFilter)}
        />

        <Select
          className="w-full sm:max-w-[400px]"
          placeholder="What would you like to do?"
          options={goalSelectOptions}
          onChange={(value) => setGoalFilter(value)}
          active={Boolean(goalFilter)}
          disabled={
            resource === resourceCategories.BLOG ||
            resource === resourceCategories.VIDEOS
          }
        />
      </div>

      <div
        className={`
        mt-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3
        md:gap-x-4 lg:gap-x-8
        gap-y-10 md:gap-y-20
        justify-items-center
      `}
      >
        {dataToRender.map((item) => (
          <ResourceCard
            key={item.id || item.title}
            {...item}
            onClick={() =>
              item.tag === resourceTags.VIDEO_TESTIMONIAL
                ? setIsModalVisible(true)
                : null
            }
            setVideoData={setVideoData}
          />
        ))}
      </div>

      {!dataToRender.length && (
        <div className="text-center text-p">No results found...</div>
      )}

      {resource === "blog" && hasMoreBlogArticles && (
        <CtaSecondary
          title="Load more"
          large
          noIcon
          className="mt-10 mx-auto"
          onClick={() =>
            loadBlogPosts(industryFilter, goalFilter, blogPostsPage)
          }
          loading={loading}
        />
      )}

      {/* <Modal */}
      {/*  visible={isModalVisible} */}
      {/*  footer={null} */}
      {/*  onCancel={() => setIsModalVisible(false)} */}
      {/* > */}
      {/*  <p>Some contents...</p> */}
      {/*  <p>Some contents...</p> */}
      {/*  <p>Some contents...</p> */}
      {/* </Modal> */}

      <YoutubeVideoModal
        {...videoData}
        onClose={() => setIsModalVisible(false)}
        isVisible={isModalVisible}
      />
    </section>
  );
};

ResourcesCards.propTypes = {
  className: string,
  defaultResource: string,
  title: string,
  pills: arrayOf(
    shape({
      value: string.isRequired,
      label: string.isRequired,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  data: object.isRequired,
};

ResourcesCards.defaultProps = {
  className: "",
  defaultResource: "all",
  title: "",
  pills: [],
};

export default ResourcesCards;
